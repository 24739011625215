var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "inspections" } },
    [
      !_vm.currentUserCanViewScaffoldInspections
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("inspections.no-permission-to-view")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view" },
        [
          _c(
            "fd-toolbar",
            {
              attrs: { loading: _vm.processing },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c("fd-add-button", {
                        attrs: {
                          disabled: !_vm.currentUserCanCreateScaffoldInspections,
                          action: _vm.openNewDialog
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "0", sm: "8" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { justify: "end" }
                        },
                        [
                          _c("v-combobox", {
                            class: [
                              _vm.tagsSelectedForFiltering.length > 0
                                ? "fd-combobox-control-item-selected"
                                : "fd-combobox-control-item-not-selected",
                              "pr-3",
                              "fd-limit-combobox-size-to-content"
                            ],
                            attrs: {
                              items: _vm.tagsInUse,
                              label: _vm.$t("common.filter-by-tags"),
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tagsSelectedForFiltering,
                              callback: function($$v) {
                                _vm.tagsSelectedForFiltering = $$v
                              },
                              expression: "tagsSelectedForFiltering"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "ml-0 mr-0 fd-primary-context-qualifier-background",
              staticStyle: { "margin-top": "30px", "padding-left": "100px" },
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "12", sm: "5" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mr-0 justify-end justify-sm-start",
                      attrs: { align: "center" }
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? "fd-timesheet-summary-date-select-wrapper"
                              : "fd-timesheet-summary-date-select-wrapper-mobile",
                            "fd-position-relative",
                            "my-2",
                            _vm.$vuetify.breakpoint.sm
                              ? "fd-inspection-date-filter-mobile-extra"
                              : "",
                            _vm.$vuetify.breakpoint.xsOnly
                              ? "fd-inspection-date-filter-xs-padding"
                              : ""
                          ]
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              class: [
                                _vm.$vuetify.breakpoint.sm
                                  ? "fd-inspection-date-filter-left-mobile-extra"
                                  : "",
                                "fd-timesheet-summary-date-selector-button-position-left",
                                _vm.$vuetify.breakpoint.sm
                                  ? "fd-timesheet-summary-date-selector-button-position-left-sm"
                                  : ""
                              ],
                              attrs: {
                                icon: "",
                                fab: "",
                                disabled: _vm.processing
                              },
                              on: { click: _vm.selectPreviousSegment }
                            },
                            [_c("v-icon", [_vm._v("fa-circle-caret-left")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("sp-scaffold-inspection-time-segment-select", {
                            attrs: {
                              selectableTimeSegments: _vm.selectableTimeSegments
                            },
                            model: {
                              value: _vm.selectedTimeRange,
                              callback: function($$v) {
                                _vm.selectedTimeRange = $$v
                              },
                              expression: "selectedTimeRange"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              class: [
                                _vm.$vuetify.breakpoint.xsOnly
                                  ? "fd-inspection-date-filter-previous-button-xs-padding"
                                  : "",
                                "fd-timesheet-summary-date-selector-button-position-right",
                                _vm.$vuetify.breakpoint.sm
                                  ? "fd-timesheet-summary-date-selector-button-position-right-sm"
                                  : ""
                              ],
                              attrs: {
                                icon: "",
                                fab: "",
                                disabled:
                                  _vm.processing || !_vm.canSelectNextSegment
                              },
                              on: { click: _vm.selectNextSegment }
                            },
                            [_c("v-icon", [_vm._v("fa-circle-caret-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "0", sm: "7" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "d-none d-sm-flex fd-inspection-status-filters",
                      attrs: { align: "center", justify: "end" }
                    },
                    [
                      _c("v-switch", {
                        staticClass:
                          "pl-3 pr-5 pr-sm-3 pr-md-5 fd-inspection-status-switch-pass",
                        attrs: {
                          "data-cy": "showpassedswitch",
                          disabled: _vm.processing,
                          label: _vm.$t(
                            "inspections.inspection-result-pass-label"
                          )
                        },
                        model: {
                          value: _vm.showPassed,
                          callback: function($$v) {
                            _vm.showPassed = $$v
                          },
                          expression: "showPassed"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-switch", {
                        staticClass:
                          "pr-5 pr-sm-3 pr-md-5 fd-inspection-status-switch-fail",
                        attrs: {
                          "data-cy": "showfailedswitch",
                          disabled: _vm.processing,
                          label: _vm.$t(
                            "inspections.inspection-result-fail-label"
                          )
                        },
                        model: {
                          value: _vm.showFailed,
                          callback: function($$v) {
                            _vm.showFailed = $$v
                          },
                          expression: "showFailed"
                        }
                      }),
                      _vm._v(" "),
                      _c("v-switch", {
                        staticClass: "pr-3 fd-inspection-status-switch-pending",
                        attrs: {
                          "data-cy": "showpendingswitch",
                          disabled: _vm.processing,
                          label: _vm.$t(
                            "inspections.inspection-result-pending-label"
                          )
                        },
                        model: {
                          value: _vm.showPending,
                          callback: function($$v) {
                            _vm.showPending = $$v
                          },
                          expression: "showPending"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scaffolds.filter-by-area-label"),
                          disabled: _vm.processing,
                          items: _vm.selectableAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedAreas,
                          callback: function($$v) {
                            _vm.selectedAreas = $$v
                          },
                          expression: "selectedAreas"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("v-select", {
                        staticClass: "pt-0 mt-0",
                        attrs: {
                          label: _vm.$t("scaffolds.filter-by-sub-area-label"),
                          disabled:
                            !_vm.selectableSubAreas.length || _vm.processing,
                          items: _vm.selectableSubAreas,
                          "item-text": "name",
                          "item-value": "id",
                          multiple: "",
                          "small-chips": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedSubAreas,
                          callback: function($$v) {
                            _vm.selectedSubAreas = $$v
                          },
                          expression: "selectedSubAreas"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        "pt-0",
                        {
                          "pb-5": !_vm.canViewContractorFilter,
                          "pb-0": _vm.canViewContractorFilter
                        },
                        "order-sm-1",
                        "order-2"
                      ],
                      attrs: {
                        cols: "12",
                        sm: _vm.canViewContractorFilter ? 6 : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _vm.$vuetify.breakpoint.lgAndUp
                            ? _c(
                                "v-menu",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-3",
                                                  staticStyle: {
                                                    "min-width": "110px"
                                                  },
                                                  attrs: {
                                                    small: "",
                                                    rounded: "",
                                                    outlined: "",
                                                    disabled: _vm.processing,
                                                    color: "#7a7a7a"
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      left:
                                                        _vm.$vuetify.breakpoint
                                                          .smAndUp
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    mdi-printer\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.$vuetify.breakpoint.smAndUp
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common.print"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "margin-left":
                                                        "2px !important"
                                                    },
                                                    attrs: { right: "" }
                                                  },
                                                  [_vm._v("mdi-menu-down")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2059421970
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    { attrs: { subheader: "" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        {
                                          staticClass:
                                            "fd-drop-down-button-badges"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheets.summary.printing.all-timesheet-rows"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-group",
                                        {
                                          staticClass: "fd-drop-down-item-group"
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.downloadAndPrintReport(
                                                    "pdf"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheets.summary.printing.details"
                                                    )
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheets.summary.printing.pdf"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.downloadAndPrintReport(
                                                    "xls"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheets.summary.printing.details"
                                                    )
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheets.summary.printing.excel"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                "data-cy": "reload",
                                small: "",
                                rounded: "",
                                outlined: "",
                                color: "#7a7a7a"
                              },
                              on: { click: _vm.reloadTableData }
                            },
                            [
                              !_vm.$vuetify.breakpoint.sm
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        left: _vm.$vuetify.breakpoint.smAndUp
                                      }
                                    },
                                    [_vm._v("mdi-reload")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.reload")))
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            class: [
                              _vm.$vuetify.breakpoint.sm
                                ? "fd-restrict-clearable-position"
                                : "",
                              "pt-0",
                              "mt-0",
                              "fd-table-search-input"
                            ],
                            attrs: {
                              "data-cy": "tablesearch",
                              "append-icon": "search",
                              label: _vm.$t("common.search"),
                              "single-line": "",
                              "hide-details": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.tablesearch,
                              callback: function($$v) {
                                _vm.tablesearch = $$v
                              },
                              expression: "tablesearch"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canViewContractorFilter
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0 pb-0 order-sm-2 order-1",
                          attrs: { cols: "12", xs: "12", sm: "6", md: "6" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "pt-0 mt-0",
                            attrs: {
                              label: _vm.$t(
                                "scaffolds.filter-by-contractor-label"
                              ),
                              disabled: _vm.processing,
                              items: _vm.selectableContractors,
                              "item-value": "id",
                              "item-text": "name",
                              multiple: "",
                              "small-chips": "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.selectedContractorIDs,
                              callback: function($$v) {
                                _vm.selectedContractorIDs = $$v
                              },
                              expression: "selectedContractorIDs"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:id.hide-when-header-text-empty",
                value: "",
                expression: "''",
                arg: "id",
                modifiers: { "hide-when-header-text-empty": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:internalNumber",
                value: _vm.$vuetify.breakpoint.lgAndUp
                  ? _vm.$t("scaffolds.tag-number-label")
                  : _vm.$t("scaffolds.tag-number-label-short"),
                expression:
                  "\n        $vuetify.breakpoint.lgAndUp\n          ? $t('scaffolds.tag-number-label')\n          : $t('scaffolds.tag-number-label-short')\n      ",
                arg: "internalNumber"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:daysStanding.hide-when-extra-small",
                value: _vm.$t("scaffolds.days-standing-label"),
                expression: "$t('scaffolds.days-standing-label')",
                arg: "daysStanding",
                modifiers: { "hide-when-extra-small": true }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:areaName.hide-when-extra-small.hide-when-header-text-empty",
                value: _vm.$t("scaffolds.area-label"),
                expression: "\n        $t('scaffolds.area-label')\n      ",
                arg: "areaName",
                modifiers: {
                  "hide-when-extra-small": true,
                  "hide-when-header-text-empty": true
                }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:subAreaName.hide-when-medium",
                value: _vm.$t("scaffolds.sub-area-label"),
                expression: "$t('scaffolds.sub-area-label')",
                arg: "subAreaName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:specificWorkLocation.hide-when-medium",
                value: _vm.$t("scaffolds.location-label"),
                expression: "$t('scaffolds.location-label')",
                arg: "specificWorkLocation",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:contractorName.hide-when-medium",
                value: _vm.$t("scaffolds.contractor-label"),
                expression: "$t('scaffolds.contractor-label')",
                arg: "contractorName",
                modifiers: { "hide-when-medium": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:lastInspectionDateTime.hide-when-small",
                value: _vm.$t("inspections.inspection-date-time-label"),
                expression:
                  "\n        $t('inspections.inspection-date-time-label')\n      ",
                arg: "lastInspectionDateTime",
                modifiers: { "hide-when-small": true }
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:inspectionStatus",
                value: _vm.$t("inspections.inspection-result-label"),
                expression: "$t('inspections.inspection-result-label')",
                arg: "inspectionStatus"
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                arg: "actions",
                modifiers: {
                  "no-sort": true,
                  "hide-when-extra-small": true,
                  "class_fd-actions-cell": true
                }
              },
              {
                name: "fd-column",
                rawName:
                  "v-fd-column:action.no-sort.show-when-extra-small.class_fd-action-cell",
                arg: "action",
                modifiers: {
                  "no-sort": true,
                  "show-when-extra-small": true,
                  "class_fd-action-cell": true
                }
              },
              {
                name: "fd-row-navigate",
                rawName:
                  "v-fd-row-navigate.no-sort.show-when-extra-small.override",
                value: item =>
                  !item.currentSegmentInspection
                    ? false
                    : _vm.$router.push(
                        `/inspections/${item.currentSegmentInspection.id}`
                      ),
                expression:
                  "\n        item =>\n          !item.currentSegmentInspection\n            ? false\n            : $router.push(`/inspections/${item.currentSegmentInspection.id}`)\n      ",
                modifiers: {
                  "no-sort": true,
                  "show-when-extra-small": true,
                  override: true
                }
              }
            ],
            class: ["fd-actions-table", "fd-requests-list"],
            attrs: {
              "data-cy": "list",
              "custom-sort": _vm.customSort,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                "items-per-page-options": _vm.itemsPerPageOptions
              },
              items: _vm.scaffoldsWithInspections,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["internalNumber"],
              "sort-desc": true,
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "item.fd-nav",
                fn: function({ item }) {
                  return [
                    !!item.currentSegmentInspection
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              color: !item.currentSegmentInspection
                                ? "rgba(0,0,0,.20)"
                                : undefined
                            }
                          },
                          [_vm._v("mdi-chevron-right")]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.inspectionStatus",
                fn: function({ item }) {
                  return [
                    _c(
                      "v-chip",
                      {
                        attrs: { color: _vm.inspectionColorForScaffold(item) },
                        on: {
                          click: function($event) {
                            return _vm.chipClicked(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                `inspections.status.${item.inspectionStatus}`
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.lastInspectionDateTime",
                fn: function({ item }) {
                  return [
                    _c(
                      "v-row",
                      {
                        staticStyle: { "flex-wrap": "nowrap !important" },
                        attrs: { "no-gutters": "" }
                      },
                      [
                        _c("fd-value-display", {
                          attrs: {
                            value: !item.lastInspectionDateTime
                              ? undefined
                              : _vm.$format.datetime(
                                  item.lastInspectionDateTime
                                )
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "ml-1",
                                            class: {
                                              "d-none": !_vm.latestInspectionCreatedAtDifferentTime(
                                                item
                                              )
                                            },
                                            attrs: {
                                              small: "",
                                              color: "black",
                                              dark: ""
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("info")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "inspections.created-on-different-day-message",
                                    [
                                      _vm.$format.datetime(
                                        item.lastInspectionCreated
                                      )
                                    ]
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.areaName",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$lookup.location(item.areaID)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.subAreaName",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$lookup.location(item.subAreaID)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.contractorName",
                fn: function({ item }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$lookup.contractor(item.contractorID)) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "item.action",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-2-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled:
                                                _vm.processing ||
                                                (!_vm.canReinspect(item) &&
                                                  !_vm.canAddNewInspection(
                                                    item
                                                  ))
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openNewInspectionForScaffoldDialog(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-plus-circle\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.add")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openHistoryDialog(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-history\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.history")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-3-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openNewInspectionForScaffoldDialog(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-plus-circle\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.add")))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openHistoryDialog(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-history\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.history")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              link: "",
                                              disabled:
                                                _vm.processing ||
                                                !item.currentSegmentInspection,
                                              to: !item.currentSegmentInspection
                                                ? ""
                                                : `/inspections/${item.currentSegmentInspection.id}`
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-eye\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("common.view")))])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }